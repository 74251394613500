import React from 'react';
import styled from 'styled-components';
import Masonry from '../utils/Masonry';
import { down } from 'styled-breakpoints';
import Flickity from 'react-flickity-component';
import Img from 'gatsby-image';

const StyledMasonry = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  transition: box-shadow 0.7s cubic-bezier(0.2, 1, 0.22, 1);

  .gatsby-image-wrapper,
  video {
    transition: transform 0.45s cubic-bezier(0.15, 0.75, 0.5, 1) 0s;
    margin-bottom: 0;
  }

  &:hover {
    .gatsby-image-wrapper,
    video {
      transform: scale(1.1);
    }
  }
`;

const BackgroundGradient = styled.div`
  background: #0f8fff;
  background: linear-gradient(to bottom right, #0f8fff, #0de2b8);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  opacity: 0.25;
`;

const HighTitle = styled.h5`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 7px !important;
  opacity: 0.75;
  color: #444;
`;

const TitleClaim = styled.h4`
  font-size: 26px;
  line-height: 36px;
`;

const Claim = styled.div`
  min-height: 250px;
  padding: 0 50px;

  ${down('md')} {
    padding: 0;
  }
`;

const StyledPortfolio = styled.div`
  ${StyledMasonry}:last-child {
    ${({ isHeading }) => !isHeading && 'box-shadow: 0 25px 60px rgba(0,0,0,0.17)'};
  }
`;

const Carousel = styled.div`
  margin-right: 15px;
  width: 80%;
  height: 350px;
  border-radius: 4px;

  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;

  .gatsby-image-wrapper {
    height: 350px;
  }

  &:after {
    content: '';
    background: #0f8fff;
    background: linear-gradient(to bottom right, #0f8fff, #0de2b8);
    box-shadow: 0 25px 60px rgba(0, 0, 0, 0.17);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    opacity: 0.25;
  }

  &.is-selected {
    opacity: 1;
  }
`;

const flickityOptions = {
  accessibility: false,
  pageDots: false,
  initialIndex: 2,
  wrapAround: true,
  freeScroll: true
};

const Portfolio = ({ items = [] }) => {
  const titleComponent = (
    <StyledMasonry key={'portfolio'} isHeading={true}>
      <Claim>
        <HighTitle>Trabajos realizados</HighTitle>
        <TitleClaim>
          Say hello to your potential team, each a master of their respective craft. Ejemplos de
          nuestro portfolio. Una muestra de nuestro último portfolio.
        </TitleClaim>
      </Claim>
    </StyledMasonry>
  );

  return (
    <StyledPortfolio>
      <div className="visible-xs">
        {titleComponent}
        <Flickity
          className={'carousel'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {items
            .filter(({ type }) => type === 'image')
            .map((item, key) => (
              <Carousel image={item.src} key={key}>
                <Img fluid={item} />
              </Carousel>
            ))}
        </Flickity>
      </div>

      <div className="hidden-xs">
        <Masonry
          breakpointCols={3}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {[titleComponent].concat(
            items.map((item, key) => (
              <StyledMasonry key={key}>
                {item.type === 'image' && <Img fluid={item} />}
                {item.type === 'video' && (
                  <video src={item.url} title={item.title} autoPlay={true} loop muted />
                )}
                <BackgroundGradient />
              </StyledMasonry>
            ))
          )}
        </Masonry>
      </div>
    </StyledPortfolio>
  );
};

export default Portfolio;
